import ArticleEvents from "./events"
import ArticleProperties from "./properties"

export { ArticleProperties as PROPERTIES, ArticleEvents as EVENTS }

export default {
  viewProperties: (title, slug, author) => ({
    [ArticleProperties.ARTICLE_TITLE]: title,
    [ArticleProperties.ARTICLE_SLUG]: slug,
    [ArticleProperties.ARTICLE_AUTHOR]: author,
  }),
}
