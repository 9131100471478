import * as selector from "../store/selectors"

import ArticleAnalytics, { EVENTS } from "../analytics"
import {
  useDisabledIntercomEffect,
  useTrackEventEffect,
} from "../../common/effects"

import { AnalyticsContext } from "../../common/utils"

import Layout from "../../layout/components/Layout"
import NonStretchedImage from "../../common/components/NonStretchedImage"
import React from "react"
import SEO from "../../layout/components/SEO"
import { connect } from "react-redux"
import { documentToComponents } from "../utils"
import get from "lodash/get"
import { graphql } from "gatsby"

function ArticleTemplate({ article, className }) {
  const { title, slug, seo, body, image, author } = article
  const avatarImageUrl = get(author, "avatar.fluid")
  const imageData = get(image, "fluid")

  const analytics = ArticleAnalytics.viewProperties(
    title,
    slug,
    author.fullName
  )

  useTrackEventEffect(EVENTS.VIEW_ARTICLE, analytics)
  useDisabledIntercomEffect()

  return (
    <AnalyticsContext.Provider value={analytics}>
      <Layout>
        <SEO title={seo.title} description={seo.description} />

        <div className="w-full md:w-3/5 mx-auto md:mt-10 mb-12 shadow shadow-lg no-underline bg-white">
          <div style={{ height: 600 }}>
            <NonStretchedImage
              fluid={imageData}
              className="h-full bg-cover bg-center"
              alt={title}
            />
          </div>
          <div className="p-6">
            <div className="flex flex-row w-full justify-between align-start">
              <h2 className="flex-col text-3xl mt-0 mb-4 text-center md:text-left text-gray-600 font-medium leading-snug">
                {title}
              </h2>
            </div>

            <div className="mb-8 font-light">{documentToComponents(body)}</div>
          </div>
        </div>
        <div className="w-full md:w-3/5 mx-auto p-6 mb-12 mt-8 border-solid border border-gray-400 bg-white">
          <div className="flex flex-row ">
            <div className="flex-col">
              <div className="text-left mr-6 h-24 w-24 relative overflow-hidden">
                <NonStretchedImage
                  fluid={avatarImageUrl}
                  alt={author.fullName}
                  style={{ borderRadius: "50%" }}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <h4 className="text-lg text-left mt-4 mb-2 text-gray-800">
                {author.fullName}
              </h4>
              <div className="text-sm text-gray-600">{author.bio}</div>
            </div>
          </div>
        </div>
      </Layout>
    </AnalyticsContext.Provider>
  )
}

const mapStateToProps = (state, props) => ({
  article: selector.internalArticleEntity$(state, props),
})

export default connect(mapStateToProps)(ArticleTemplate)

export const query = graphql`
  query articleQuery($slug: String) {
    contentfulArticle(slug: { eq: $slug }) {
      id
      title
      subTitle
      createdAt
      body {
        json
      }
      seo {
        title
        description
      }
      image {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      author {
        id
        fullName
        bio
        avatar {
          fluid(quality: 100, maxWidth: 100, maxHeight: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
